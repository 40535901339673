// Deneb Footer css
.deneb_footer{
	.widget_wrapper{
	    background-repeat: no-repeat;
	    background-size: cover;
	    padding-top: 200px;
	    padding-bottom: 70px;
	    .widget{
	    	@media #{$xs}{
				margin-bottom: 40px;
			}
	    	.widget_title{
	    		margin-bottom: 30px;
	    		h4{
	    			font-weight: bold;
	    			&:after{
	    				content: '';
					    display: block;
					    background: url(../images/shape/line.png) no-repeat;
					    max-width: 38px;
					    height: 2px;
					    margin-top: 5px;
	    			}
	    		}
	    	}
	    }
	    .widegt_about{
	    	p{
	    		margin-bottom: 20px;
	    	}
	    	.social{
	    		li{
	    			display: inline-block;
	    			margin-right: 10px;
	    			a{
	    				display: block;
					    width: 30px;
					    height: 30px;
					    line-height: 30px;
					    text-align: center;
					    border-radius: 50%;
					    background-color: #f9e6d4;
					    color: #fba543;
					    font-size: 14px;
					    @include transition( all .5s);
					    &:hover,&:focus{
							background-image: linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
							background-image: -moz-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
							background-image: -webkit-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
							background-image: -ms-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
					    	color: $white;
					    	box-shadow: 2.5px 4.33px 15px 0px rgba(254, 176, 0, 0.4);
					    }
	    			}
	    		}
	    	}
	    }
	    .widget_link{
	    	ul{
	    		li{
	    			margin-bottom: 5px;
	    			a{
	    				text-transform: capitalize;
	    				color: $text;
	    				&:hover,
	    				&:focus{
	    					color: $theme_color;
	    				}
	    			}
	    		}
	    	}
	    }
	    .widget_contact{
	    	.contact_info{
	    		.single_info{
	    			max-width: 250px;
	    			display: flex;
		    		flex-wrap: wrap;
		    		margin-bottom: 20px;
		    		.icon{
		    			font-size: 12px;
		    			color: $theme_color;
		    			margin-right: 10px;
		    		}
		    		.info{
		    			p{
		    				& a{
		    					color: $text;
		    				}
		    				& span{
			    				display: block;
			    			}
		    			}
		    		}
	    		}
	    	}
	    }
	}
	.copyright_area{
		background: #edecf0;
		padding: 10px 0;
		.copyright_text{
			text-align: center;
			p{
				color: $title;
				span{
					color: $theme_color;
				}
			}
		}
	}
}
.footer{
	margin: 0px;	
	.footer-top{
		background-color: #f7f7fd;
		.widget_wrapper{
			background-repeat: no-repeat;
			background-size: cover;
			.widget{
				@media #{$xs}{
					margin-bottom: 40px;
				}
				.widget_title{
					margin-bottom: 30px;
					h4{
						font-weight: bold;
						&:after{
							content: '';
							display: block;
							background: url(../images/shape/line.png) no-repeat;
							max-width: 38px;
							height: 2px;
							margin-top: 5px;
						}
					}
				}
			}
			.widegt_about{
				p{
					margin-bottom: 20px;
				}
				.social{
					li{
						display: inline-block;
						margin-right: 10px;
						a{
							display: block;
							width: 30px;
							height: 30px;
							line-height: 30px;
							text-align: center;
							border-radius: 50%;
							background-color: #f9e6d4;
							color: #fba543;
							font-size: 14px;
							@include transition( all .5s);
							&:hover,&:focus{
								background-image: linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
								background-image: -moz-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
								background-image: -webkit-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
								background-image: -ms-linear-gradient( 0deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
								color: $white;
								box-shadow: 2.5px 4.33px 15px 0px rgba(254, 176, 0, 0.4);
							}
						}
					}
				}
			}
			.widget_link{
				ul{
					li{
						margin-bottom: 5px;
						.far{
							font-size: 13px;
							color: $theme_color;
							margin-right: 10px;
						}
						a{
							text-transform: capitalize;
							color: $text;
							&:hover,
							&:focus{
								color: $theme_color;
							}
						}
					}
				}
			}
			.widget_contact{
				.contact_info{
					.single_info{
						max-width: 250px;
						display: flex;
						flex-wrap: wrap;
						margin-bottom: 20px;
						.icon{
							font-size: 12px;
							color: $theme_color;
							margin-right: 10px;
						}
						.info{
							p{
								& a{
									color: $text;
								}
								& span{
									display: block;
								}
							}
						}
					}
				}
			}
			.form_control{
				border-radius: 30px;
				width: 100%;
				height: 45px;
				background: #fff;
				padding-left: 20px;
				box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
			}
			.deneb_btn{
				width: 100%;
				box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
			}
		}
	}
	.copyright_area{
		background: #f1f1fd;
		padding: 10px 0;
		.copyright_text{
			text-align: center;
			p{
				color: $title;
				span{
					color: $theme_color;
				}
			}
		}
	}
}