// Header area css
.header_area{
	position: relative;
	.site_menu{
		@media #{$xs}{
			display: none;
		}
		@media #{$md}{
			display: none;
		}
		.primary_menu{
			float: right;
			.main_menu{
				ul{
					li{
						position: relative;
						display: inline-block;
						margin-left: 40px;
						a{
							display: block;
							padding: 17px 0;
							color: #76787b;
							font-weight: 500;
							position: relative;
							@media #{$lg}{
								font-size: 14px;
							}
						}
						.deneb_btn{
							padding: 0;
							color: $white;
						}
						& ul.sub-menu{
							position: absolute;
							top: 100%;
							left: 0;
							width: 200px;
							padding: 10px 0;
							margin:0;
							text-align: left;
							background: $white;
							opacity: 0;
							border-radius: 8px;
							visibility: hidden;
							@include transform(scale(1,0));
							@include transform-origin(0% 0%);
							@include transition(.2s);
							& li{
								position: relative;
								display: block;
								margin:0;
								& a{
									padding: 5px 20px;
									color: $text;
								}
								&:hover{
									& ul.sub-menu{
										visibility: visible;
										opacity: 1;
									}
									& a{
										color: $theme_color;
									}
								}
								& ul.sub-menu{
									top: 0;
									left: 100%;
									opacity: 0;
									visibility: hidden;
									& li{
										& a{
											display: block;
											padding: 5px 20px;
											color: $text;
										}
										&:hover{
											& a{
												color: $theme_color;
											}
										}
									}
								}
							}
						}
						&:hover{
							& ul.sub-menu{
								@include transform(scale(1));
								visibility: visible;
								opacity: 1;
								box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
							}
						}
					}
					.active_link{
						&:after{
							content: '';
							position: absolute;
							left: 0;
							bottom: 10px;
							width: 20px;
							height: 3px;
							background: $theme_color;
						}
					}
				}
			}
		}
	}
	& .mobile_wrapper{
		display: none;
		@media #{$md}{
			padding: 10px 0;
			display: block;
		}
		@media #{$xs}{
			padding: 10px 0;
			display: block;
		}
		& .menu_button{
			float: right;
			& .menu_icon{
				& span{
					display: block;
					width: 30px;
					height: 3px;
					background-color: $title;
					margin: 6px 0 2px;
					@include transition(.5s);
					&:nth-child(1){
						margin-top: 0;
					}
					&:nth-child(3){
						width: 15px;
						margin-bottom: 0;
					}
				}
			}
			& .active{
				& span{
					&:nth-child(1){
						width: 15px;
					}
					&:nth-child(3){
						width: 30px;
					}
				}
			}
		}
	}
	& .sidenav_menu{
		overflow: hidden;
	    width: 80%;
	    height: 100%;
	    position: fixed;
	    top: 0;
	   	left: 0;
	    padding: 50px 30px;
	    z-index: 9999;
	    background: #222d32;
	    opacity: .9;
	    overflow: auto;
	    @include transform(translateX(-100%));
	    @include transition(.5s);
	    & .close_icon{
	    	position: absolute;
	    	top: 0;
	    	right: 0;
	    	.close_btn{
	    		display: block;
	    		width: 40px;
	    		height: 40px;
	    		line-height: 40px;
	    		text-align:center;
	    		background-color: $white;
	    		color: $theme_color;
	    	}
	    }
	    & .sidebar-menu{
	    	& li{
	    		display: block;
	    		line-height: 40px;
	    		& a{
	    			text-transform: capitalize;
	    			color: $white;
	    			font-size: 16px;
	    			font-weight: 500;
	    			&:hover,
	    			&:focus{
	    				color: $theme_color;
	    			}
	    			& .deneb_btn{
		    			padding: 0;
		    		}
	    		}
	    	}
	    	& .active{
				a{
					color: $theme_color;
				}
			}
	    }
	}
	& .active{
		@include transform(translateX(0));
	}
}
// transparent Header
.transparent_header{
	position: absolute;
	top: 33px;
	left: 0;
	width: 100%;
	z-index: 337;
}
// sticky_header
.header_area.sticky{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: $white;
	z-index: 337;
	box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
	.site_menu{
		padding: 10px 0;
	}
}

.header_two.sticky {
	.site_menu .primary_menu .main_menu ul li {
		& a{	
			color: $text;
		}
		.deneb_btn{
			padding: 0;
			color: $white;
		}
		
	}
	.mobile_wrapper .menu_button .menu_icon{
		span {
			background-color: $title;
		}
	}
}

.header_two {
	.site_menu .primary_menu .main_menu ul li{
		& a{
			color: $white;
		}
		.deneb_btn{
			padding: 0;
			color: $white;
		}
	}
	.mobile_wrapper .menu_button .menu_icon{
	    & span{
			background-color: $white;

		}
	}

}
